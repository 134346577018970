import './App.css';
import Menu from "./components/menu";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Portfolio from "./components/portfolio";
import Home from "./components/home";
import 'react-photo-view/dist/react-photo-view.css';

function App() {
    return (
        <div className="App">
            <Router>
                <Menu/>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
