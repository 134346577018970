import profile from '../img/profile/profile.webp';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {ImProfile} from "react-icons/im";

export default function Home() {
    return (
        <div className={"content-profile"}>
            <div className={"profile-photo-title"}>
                <div className="img-profile">
                    <img src={profile} alt="vickie guillozet"/>
                </div>
                <div className="profile-title">
                    <h2>Vickie Guillozet</h2>
                    <h3>UX Researcher</h3>
                    <h4>8 ans d'expérience</h4>
                </div>
            </div>
            <div className="profile-contact">
                <h2>Contact</h2>
                <p>Lugrin, France</p>
                <p>Tel: +33 6 34 15 09 88</p>
                <p>Email: vickie.guillozet@gmail.com</p>
            </div>
            <div className="social-media">
                <a target="_blank" rel="noopener noreferrer"
                   href="https://ch.linkedin.com/in/vickie-desnottes">
                    <FontAwesomeIcon size="4x" icon={faLinkedin} style={{color: "rgb(30, 48, 80)"}}/>
                </a>
                <a className="social-media-cv" target="_blank" rel="noopener noreferrer"
                   href="files/CV_Vickie_GUILLOZET.pdf">
                    <ImProfile size={"3.5em"} style={{color: "rgb(30, 48, 80)"}}/>
                </a>
            </div>
        </div>
    )
}