import {Link, useLocation} from "react-router-dom";

export default function Menu() {
    const location = useLocation();

    return (
        <div className="menu">
            <div className={"menu-item" + (location.pathname === "/" ? " active" : "")}>
                <Link to="/">Home</Link>
            </div>
            <div className={"menu-item" + (location.pathname === "/portfolio" ? " active" : "")}>
                Portfolio
            </div>
        </div>
    )
}