import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import tcsLogo from '../img/tpg.png';
import scottLogo from '../img/scott.png';
import SchoolIcon from '@mui/icons-material/School';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import school1 from '../img/school/school1.webp';
import school2 from '../img/school/school2.webp';
import school3 from '../img/school/school3.webp';
import school4 from '../img/school/school4.png';
import {PhotoProvider, PhotoView} from 'react-photo-view';
import scott1 from '../img/scott/scott1.png';
import scott2 from '../img/scott/scott2.png';
import assurance1 from '../img/assurance/assurance1.webp';
import tpg1 from '../img/tpg/tpg1.webp';
import tpg2 from '../img/tpg/tpg2.webp';
import tpg3 from '../img/tpg/tpg3.webp';
import tpg4 from '../img/tpg/tpg4.webp';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';

export default function Portfolio() {
    return (
        <div className={"content-portfolio"}>
            <VerticalTimeline lineColor={'#d1242a'} layout={'1-column-left'}>
                <VerticalTimelineElement
                    contentStyle={{background: 'rgb(227, 227, 227)'}}
                    contentArrowStyle={{borderRight: '7px solid  rgb(227, 227, 227)'}}
                    className="vertical-timeline-element--work"
                    iconStyle={{background: '#d1242a', color: '#fff'}}
                    icon={<SchoolIcon/>}
                >
                    <h3 className="vertical-timeline-element-title">Ecole de Management, soumis à NDA</h3>
                    <p className="vertical-timeline-element-subtitle"><b>Recherche combinée (Conversion Rate
                        Optimisation
                        +
                        User Research) pour améliorer les taux de conversion.</b></p>
                    <div>
                        <p>
                            Conjointement avec un expert analytique nous avons mis en place une méthodologie pour avoir
                            des
                            données quantitatives et qualitatives sur les utilisateurs:
                        </p>
                        <ul>
                            <li>
                                <p><b>Tree Test</b> sur l’arborescence actuelle. Effectué avec Optimal Workshop, nous
                                    l’avons
                                    réalisé sur deux types de visiteurs : des nouveaux qui n’avaient jamais vu le site,
                                    et
                                    des personnes habituées au site. Cela nous a permi de comprendre si un effet
                                    d’apprentissage intervenait dans la navigation (ce n’était pas le cas, les
                                    utilisateurs
                                    apprenaient à utiliser l’outil de recherche des programmes plutôt qu’à naviguer),
                                    mais
                                    également s’il y avait des problèmes de taxonomie dans l’arborescence (c’était bien
                                    le
                                    cas, les utilisateurs ne comprenaient pas les différentes catégories).</p>
                            </li>
                            <li>
                                <p><b>Analyse des funnels de conversions</b> et des drops associés (expert
                                    analytique/SEO)
                                    grâce
                                    aux outils mis en place comme GA4 (Google Analytics).</p>
                            </li>
                            <li>
                                <p><b>Behavioural analysis</b> avec Crazy Eggs (Heat Map, Rage click, enregistrement de
                                    sessions,
                                    zones de scroll, …). Cela nous a permis d’expliquer les drops de conversion trouvés
                                    à
                                    l’étape précédente. Les scrollmap ont montré qu’il y avait beaucoup trop de contenu
                                    (surtout sur mobile) et que les utilisateurs ne voyaient pas les informations
                                    importantes car elles se situaient trop bas dans les pages. De plus, l'affordance de
                                    certaines icônes n’était pas optimale comme en démontrait le taux de rage click
                                    (certains utilisateurs s’acharnaient sur des icônes en pensant qu’elles étaient
                                    cliquables, mais ce n’était pas le cas).</p>
                            </li>
                            <li>
                                <p>
                                    <b>Tests d’utilisabilité modérés</b> afin de creuser les hypothèses et éléments qui
                                    ont
                                    émergé
                                    des étapes précédentes. Cela a notamment permis de valider les problèmes de
                                    taxonomie et
                                    d’avoir des pistes de réflexions pour renommer les catégories. Nous avons également
                                    pû
                                    valider notre hypothèse que la sous navigation interne à certaines pages n’était pas
                                    vue
                                    et que cela était confusant pour les utilisateurs. Nous avons également pu mettre en
                                    lumière les différences entre mobile et desktop avec un site pas conçu en “Mobile
                                    first”, qui pourtant était majoritairement utilisé sur mobile comme l’a montré
                                    l’analytique.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>Rapport d’analyses conjointes et recommandations.</b> Ce rapport et ses
                                    recommandations ont
                                    ensuite été présentés à la hiérarchie du client afin de définir une roadmap pour les
                                    intégrer au site.
                                </p>
                            </li>

                        </ul>
                        <PhotoProvider>
                            <div className={"photo-slider"}>
                                <PhotoView key={"school4"} src={school4}>
                                    <figure>
                                        <img
                                            src={school4}
                                            className={"img_picture"}
                                            alt="school4"/>
                                        <figcaption><p>Extrait du guide mélangeant test et interview utilisateur</p>
                                        </figcaption>
                                    </figure>
                                </PhotoView>
                                <PhotoView key={"school1"} src={school1}>
                                    <figure>
                                        <img
                                            src={school1}
                                            className={"img_picture"}
                                            alt="school1"/>
                                        <figcaption><p>Hypothèse d'un temps de chargement de page trop long étayée par
                                            les données de Crazy Egg</p></figcaption>
                                    </figure>
                                </PhotoView>
                                <PhotoView key={"school2"} src={school2}>
                                    <figure>
                                        <img
                                            src={school2}
                                            className={"img_picture"}
                                            alt="school2"/>
                                        <figcaption><p>Hypothèse de pages avec trop de scroll étayée par les scrollmap
                                            de Crazy Egg</p></figcaption>
                                    </figure>
                                </PhotoView>
                                <PhotoView key={"school3"} src={school3}>
                                    <figure>
                                        <img
                                            src={school3}
                                            className={"img_picture"}
                                            alt="school3"/>
                                        <figcaption><p>Hypothèse de mauvais positionnement d'un CTA étayée par une
                                            heatmap et une scrollmap de Crazy Egg</p></figcaption>
                                    </figure>
                                </PhotoView>

                            </div>
                        </PhotoProvider>
                    </div>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{background: 'rgb(227, 227, 227)'}}
                    contentArrowStyle={{borderRight: '7px solid  rgb(227, 227, 227)'}}
                    icon={<img
                        src={scottLogo}
                        className="img_logo"
                        style={{
                            border: "1px solid #d1c8c8"
                        }}
                        alt="scottLogo"/>}
                >
                    <h3 className="vertical-timeline-element-title">SCOTT Sports</h3>
                    <p className="vertical-timeline-element-subtitle"><b>La navigation du site ne permettait pas de
                        mettre
                        en
                        valeur tous les produits de la marque qui avait du mal à être reconnue pour d’autres produits
                        que ses vélos.</b></p>
                    <div>
                        <p>Dans un premier temps il a fallu comprendre quels étaient les besoins de tous les services
                            (découpage en un service par produit ou type de produit). Pour se faire, en équipe avec des
                            UX
                            Designer nous avons réalisés:</p>
                        <ul>
                            <li>
                                <p><b>Interviews utilisateurs</b> de chacun des représentants des différents services
                                    afin
                                    de
                                    comprendre leurs problématiques, leurs objectifs et leur vision de la prochaine
                                    navigation du site.</p>
                            </li>
                            <li>
                                <p><b>Analyse des interviews</b> avec workshop de consolidation afin d’avoir un
                                    consensus
                                    des
                                    parties prenantes et les amener à avoir une vision commune de la prochaine
                                    navigation.</p>
                            </li>
                            <li>
                                <p>
                                    <b>Tri de cartes</b> pour que les différents services explicitent les différentes
                                    catégories de
                                    produits à représenter dans le site (ainsi que les catégories venant des campagnes
                                    marketing). Ce tri de carte a été fait de manière ouverte : les parties prenantes
                                    pouvaient à loisir ajouter des cartes (pour des produits qui n’auraient pas été
                                    mentionnés jusqu’alors), et les catégories n’avaient pas été définies pour qu’ils
                                    puissent utiliser les termes qui leur convenaient.
                                </p>
                            </li>
                        </ul>
                        <p>Dans un second temps il a fallu construire différentes navigations avant de les tester:</p>
                        <ul>
                            <li>
                                <p>
                                    <b>Analyse du tri de cartes</b> grâce à un <b>dendrogramme</b> et une <b>matrice
                                    d’affinité</b>.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>Construction de 2 arborescences</b> correspondant à ce dendrogramme et cette
                                    matrice.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>Réalisation de tree tests</b> sur chacunes de ces deux navigations afin de
                                    comprendre
                                    laquelle correspondrait le mieux aux utilisateurs. Les critères de recrutement ont
                                    été
                                    définis conjointement avec le client. Le recrutement a ensuite été effectué en
                                    partie
                                    grâce à leur base de données mais également grâce à une agence de recrutement.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>Présentation des résultats</b> aux acteurs impliqués chez le client afin de leur
                                    présenter
                                    la meilleure navigation répondant à toutes les problématiques précédemment définies.
                                </p>
                            </li>
                        </ul>
                        <PhotoProvider>
                            <div className={"photo-slider"}>
                                <PhotoView key={"scott1"} src={scott1}>
                                    <figure>
                                        <img
                                            src={scott1}
                                            className={"img_picture"}
                                            alt="scott1"/>
                                        <figcaption><p>Graphiques représentant les taux de succès et d'erreur de manière
                                            directes ou indirectes. Deux navigations testées sur la même tâche lors d'un
                                            tree test</p></figcaption>
                                    </figure>
                                </PhotoView>
                                <PhotoView key={"scott2"} src={scott2}>
                                    <figure>
                                        <img
                                            src={scott2}
                                            className={"img_picture"}
                                            alt="scott2"/>
                                        <figcaption><p>Pie tree d'une tâche pour deux navigations différentes lors d'un
                                            tree test</p>
                                        </figcaption>
                                    </figure>
                                </PhotoView>
                            </div>
                        </PhotoProvider>
                    </div>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    contentStyle={{background: 'rgb(227, 227, 227)'}}
                    contentArrowStyle={{borderRight: '7px solid  rgb(227, 227, 227)'}}
                    className="vertical-timeline-element--work"
                    iconStyle={{background: '#d1242a', color: '#fff'}}
                    icon={<HealthAndSafetyIcon/>}
                >
                    <h3 className="vertical-timeline-element-title">Compagnie d'assurance</h3>
                    <p className="vertical-timeline-element-subtitle"><b>L’espace client avait besoin d’être revu car il
                        générait beaucoup d’appels téléphoniques sur la ligne d’assistance. Je n’ai pas participé à
                        cette refonte, mais j’étais la personne chargée de la recherche sur l'expérience utilisateur afin de s’assurer
                        que la refonte avait été bien faite.</b></p>
                    <div>
                        <p>Pour tester ce nouvel espace client j’ai réalisé:</p>
                        <ul>
                            <li>
                                <p>
                                    <b>Tests d’utilisabilité</b> façon <b>A/B Test qualitatif</b>, sur le prototype de
                                    la nouvelle version
                                    mais également sur la version actuelle qui était en ligne. Afin d’éliminer des biais
                                    qui
                                    auraient pu intervenir entre une version prototype et une version en ligne (temps de
                                    chargement, bannières publicitaires, …) l’équipe UI a réalisé un prototype de la
                                    version
                                    qui était en ligne. Nous avons ainsi pu réaliser des tests d’utilisabilité sur ces
                                    deux
                                    plateformes en version prototype avec les mêmes scenarii de tests. Cela permettait
                                    une
                                    comparaison du temps entre les différentes tâches, des parcours, ainsi que des
                                    verbatims
                                    des testeurs. Chaque utilisateur était chargé de réaliser deux fois le scénario de
                                    test,
                                    un coup sur un prototype puis l’autre. Nous avons alterné les utilisateurs qui
                                    commençaient par le nouveau et par l’ancien afin de lisser le biais d’apprentissage.
                                    Les
                                    critères de recrutement avaient été définis conjointement avec le client pour les
                                    âges,
                                    le fait d’être prospect ou non, et dans tous les cas être le gestionnaire des
                                    démarches
                                    d’assurance dans le foyer.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>Questionnaires SUS et NPS</b>: après chaque déroulé d’un scénario, nous
                                    demandions aux
                                    utilisateurs de remplir les questionnaires SUS et NPS afin par la suite de comparer
                                    les
                                    résultats entre la version actuelle et la nouvelle version à développer.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>Synthèse et analyse des résultats</b> qui ont confirmé au client que la nouvelle
                                    version
                                    était bien meilleure que celle en ligne. Cela a permis de le rassurer et de valider
                                    la
                                    décision de se lancer dans le développement de cette version, tout en intégrant de
                                    légères modifications identifiées grâce aux tests.
                                </p>
                            </li>
                        </ul>
                        <PhotoProvider>
                            <div className={"photo-slider"}>
                                <PhotoView key={"assurance1"} src={assurance1}>
                                    <figure>
                                        <img
                                            src={assurance1}
                                            className={"img_picture"}
                                            alt="assurance1"/>
                                        <figcaption><p>Extrait du rapport présentant des éléments de comparaison entre
                                            l'ancienne et la nouvelle version de l'espace client</p>
                                        </figcaption>
                                    </figure>
                                </PhotoView>
                            </div>
                        </PhotoProvider>
                    </div>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{background: 'rgb(227, 227, 227)'}}
                    contentArrowStyle={{borderRight: '7px solid  rgb(227, 227, 227)'}}
                    icon={<img src={tcsLogo} className="img_logo" alt="tcsLogo"/>}
                >
                    <h3 className="vertical-timeline-element-title">TPG</h3>
                    <p className="vertical-timeline-element-subtitle"><b>Les conducteurs des TPG étaient frustrés de ne
                        pas pouvoir répondre aux clients quant à leurs inquiétudes sur l’état du trafic. De plus ils
                        étaient mal à l’aise quand d’autres clients répondaient à leurs places car ils avaient plus
                        d’informations qu’eux sur les causes d’un trafic perturbé. Pour répondre à cette problématique,
                        il a été décidé de leur fournir une application qui les informerait sur l’état du trafic mais
                        également qui leur permettrait de facilement créer des avaries, ce qui était long et fastidieux sur
                        leur portail habituel.</b></p>
                    <div>
                        <p>
                            Lors de cette mission j’ai pû dérouler une conception centrée utilisateur en entier:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b>Observations de terrain et interviews</b> où j’ai pu comprendre les différents
                                    contextes,
                                    problématiques et besoins des conducteurs. En effet, cette application ne pouvait
                                    pas
                                    être consultée en conduisant, mais uniquement lors des pauses aux terminus. Ces
                                    pauses
                                    était cependant courtes, et devaient également permettre aux conducteurs de faire
                                    d’autres choses (passer un appel, manger un sandwich, …). J’ai pu ainsi discuter
                                    avec
                                    eux et les observer pour comprendre cette problématique de temps, cette frustration
                                    face
                                    à leur incapacité de répondre aux clients et le contexte dans lequel serait consulté
                                    l’application.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>Formalisation</b> de la phase d’observation grâce à <b>des personas et des users
                                    journey</b> pour
                                    pouvoir communiquer facilement avec les développeurs et leur faire comprendre les
                                    problématiques à résoudre.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>Ateliers de co-création</b> avec un panel d’utilisateurs recrutés parmi les
                                    conducteurs
                                    ainsi qu’une développeur et la cheffe de projet. Lors de ces ateliers différentes
                                    méthodes ont été utilisées (telles que le design Studio, le diagramme café, le
                                    modèle de
                                    Kano, des innovations games) afin de définir les fonctionnalités à avoir et les
                                    prioriser, définir l’importance des informations à visualiser, les cinématiques
                                    ainsi
                                    qu’une idée de design. Le fait d’intégrer un développeur et la cheffe de projet à la
                                    démarche a permis de valider la faisabilité technique et financière avant de
                                    commencer
                                    la conception.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Réalisation de <b>maquettes</b> cliquables sur la base de ces données.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Passation de <b>tests utilisateurs</b> avec un panel différent de celui ayant
                                    réalisé les
                                    ateliers. Ces tests ont été fait de manière itérative, en présentant à chaque fois
                                    des
                                    améliorations des maquettes jusqu’à l’obtention d’un consensus vis-à -vis des
                                    utilisateurs. Les premiers tests étaient réalisés en présentiel de
                                    manière <b>qualitative</b> afin d’avoir les premières améliorations à apporter. Une fois que les maquettes
                                    étaient
                                    satisfaisantes, ils ont été réalisés en distanciel de manière
                                    plus <b>quantitative</b> sur un
                                    plus grand nombre de testeurs, avec un formulaire à remplir à la fin des tests.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>Les maquettes définitives</b> ainsi que le <b>rapport de test</b> ont ensuite été
                                    livrés à des
                                    comités de direction au sein des TPG et ont eu un grand succès. L’application a donc
                                    été
                                    développée.
                                </p>
                            </li>
                        </ul>
                        <PhotoProvider>
                            <div className={"photo-slider"}>
                                <PhotoView key={"tpg1"} src={tpg1}>
                                    <figure>
                                        <img
                                            src={tpg1}
                                            className={"img_picture"}
                                            alt="tpg1"/>
                                        <figcaption><p>Photo prise lors d'une observation de terrain</p></figcaption>
                                    </figure>
                                </PhotoView>
                                <PhotoView key={"tpg2"} src={tpg2}>
                                    <figure>
                                        <img
                                            src={tpg2}
                                            className={"img_picture"}
                                            alt="tpg2"/>
                                        <figcaption><p>Extrait d'une empathy map résumant les observations et interview</p>
                                        </figcaption>
                                    </figure>
                                </PhotoView>
                                <PhotoView key={"tpg3"} src={tpg3}>
                                    <figure>
                                        <img
                                            src={tpg3}
                                            className={"img_picture"}
                                            alt="tpg3"/>
                                        <figcaption><p>Maquette cliquable pour réaliser des tests utilisateurs</p></figcaption>
                                    </figure>
                                </PhotoView>
                                <PhotoView key={"tpg4"} src={tpg4}>
                                    <figure>
                                        <img
                                            src={tpg4}
                                            className={"img_picture"}
                                            alt="tpg4"/>
                                        <figcaption><p>Extrait des premiers résultats de tests sur 33 participants</p>
                                        </figcaption>
                                    </figure>
                                </PhotoView>
                            </div>
                        </PhotoProvider>
                    </div>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{background: 'rgb(227, 227, 227)'}}
                    contentArrowStyle={{borderRight: '7px solid  rgb(227, 227, 227)'}}
                    iconStyle={{background: '#d1242a', color: '#fff'}}
                    icon={<MoreHorizIcon/>}
                >
                    <p className="vertical-timeline-element-subtitle">Cette liste n'est évidemment pas exhaustive, n'hésitez pas à me contacter pour en savoir plus.</p>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    )
}